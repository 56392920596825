import { configureStore } from "@reduxjs/toolkit";
import bookButton from "store/bookButton/bookButtonSlice";
import currentUser from "store/currentUser/currentUserSlice";
import ui from "store/ui/uiSlice";
import letterbooks from "store/letterbooks/letterbooksSlice";
import search from "store/search/searchSlice";

const store = configureStore({
  reducer: {
    currentUser,
    bookButton,
    letterbooks,
    ui,
    search,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export { store };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
