"use client";

import { QuickSearchModal } from "components/search/QuickSearchModal/parts/QuickSearchModal";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchVisibleSelector } from "store/search/searchSelector";
import { searchActions } from "store/search/searchSlice";
import { indexLookup } from "lib/search/indexLookup";
import { SearchContextType } from "../search/types";

const searchOptions: SearchContextType = {
  initialSearchType: "Books",
  searchTypes: [
    "Books",
    "Authors",
    "Series",
    "Lists",
    "Prompts",
    "Characters",
    "Users",
    "Publishers",
  ],
  searchMultipleSources: true,
  initialUiState: {
    [indexLookup("Books")]: {
      query: "",
    },
  },
};

const QuickSearchModalWrapper = () => {
  const isVisible = useSelector(getSearchVisibleSelector);
  const dispatch = useDispatch();
  const setShowQuickSearch = useCallback((visible = false) => {
    dispatch(searchActions.setSearchVisible(visible));
  }, []);

  return (
    <QuickSearchModal
      isOpen={isVisible}
      onClose={setShowQuickSearch}
      searchOptions={searchOptions}
    />
  );
};

export default QuickSearchModalWrapper;
