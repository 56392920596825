import classNames from "lib/classNames";
import { ComponentTheme } from "hardcover-ui/types/base";

export type ModalThemeProps = {
  variant: "primary";
  size: "xs" | "sm";
};

const modalClass = classNames(
  "w-full h-screen transform overflow-hidden text-left align-middle transition-all bg-white dark:bg-gray-800",
  "lg:rounded-2xl lg:shadow-xl lg:border lg:border-gray-500 lg:h-auto"
);

export const modalTheme: ComponentTheme<ModalThemeProps> = {
  variant: {
    primary: `${modalClass} mx-auto`,
  },
  size: {
    xs: "lg:max-w-xl",
    sm: "lg:max-w-3xl",
  },
};
