"use client";

import { Suspense, lazy } from "react";

const MobileManagerWrapper = lazy(
  () => import("components/background/MobileManagerWrapper")
);
const PreloadResourceManager = lazy(
  () => import("components/background/PreloadResourceManager")
);
const PlausibleAnalytics = lazy(() => import("./PlausibleAnalytics"));
const SaveReferrer = lazy(() => import("./SaveReferrer"));
const ThemeManager = lazy(() => import("./ThemeManager"));

export default function BackgroundManager() {
  return (
    <Suspense>
      <MobileManagerWrapper />
      <ThemeManager />
      <PreloadResourceManager />
      <PlausibleAnalytics />
      <SaveReferrer />
    </Suspense>
  );
}
