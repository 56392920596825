import { gql } from "graphql-tag";
import { UserType } from "types";

export const bootstrapUserByUserId = gql`
  query BootstrapUserById($userId: Int!) {
    user: users_by_pk(id: $userId) {
      ...OwnerFragment
    }
  }
`;

export interface BootstrapUserByIdQueryType {
  user: UserType;
}
