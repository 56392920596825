/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LetterbookSettingType } from "features/Letterbooks/types";

export type LetterBooksStateType = {
  isLetterbookList: boolean;
  open: boolean;
  settings: LetterbookSettingType | null;
};

export const letterbooksState: LetterBooksStateType = {
  isLetterbookList: false,
  open: false,
  settings: null,
};

const letterbooksSlice = createSlice({
  name: "LetterbooksSlice",
  initialState: letterbooksState,
  reducers: {
    setIsLetterbookList(state, { payload }) {
      state.isLetterbookList = payload;
      if (!payload) {
        state.settings = null;
      }
    },
    setIsLetterbooksDrawer(state, { payload }) {
      state.open = payload;
    },
    hideLetterbooksDrawer(state) {
      state.open = false;
    },
    setLetterbooksSettings(
      state,
      { payload }: { payload: LetterbookSettingType }
    ) {
      state.isLetterbookList = true;
      state.settings = payload;
    },
  },
});

export const letterbookActions = letterbooksSlice.actions;

export default letterbooksSlice.reducer;
