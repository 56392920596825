"use client";

import { Suspense, lazy, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { currentUserActions } from "store/currentUser/currentUserSlice";
import { UserType } from "types";
import { HardcoverSession } from "lib/authorization/options";
import { bootstrapUserByUserId } from "queries/users/bootstrapUserById";
import { getClient } from "lib/apollo/client";

const NotificationsUpdater = lazy(() => import("./NotificationsUpdater"));
const CurrentUserClientManager = lazy(
  () => import("./CurrentUserClientManager")
);

// Loads everything about the logged in user on the client side
interface Props {
  session: HardcoverSession;
  user?: UserType;
}
export default function CurrentUserClientLoader({ session, user }: Props) {
  const initialized = useRef(false); // Prevents duplicate loading for some reason
  const loaded = useRef(false);
  const dispatch = useDispatch();

  // This will load all bootstrapped data into Apollo's fragment cache
  // Side note:
  //   I'd love to get rid of this and hand off the server cache
  //   to the client cache, but that's not currently possible.
  function loadFragmentCache() {
    getClient().writeQuery({
      query: bootstrapUserByUserId,
      data: { user },
      variables: {
        userId: user.id,
      },
    });
  }

  // Set the session and user in Redux
  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      if (user) {
        loadFragmentCache();
      }

      dispatch(currentUserActions.setSession(session));
      dispatch(currentUserActions.setInitialUser(user as UserType));
      loaded.current = true;
    }
  }, []);

  if (!loaded) {
    return false;
  }

  return (
    <Suspense>
      <CurrentUserClientManager />
      <NotificationsUpdater />
    </Suspense>
  );
}
