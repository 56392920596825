"use client";

import { useCurrentSession } from "hooks/useCurrentSession";
import { throttle } from "lodash";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { toast } from "sonner";

export default function Signout({
  redirect = "/join",
  message = "You have been logged out. Redirecting...",
}: {
  redirect?: string;
  message?: string;
}) {
  const { resetSession } = useCurrentSession();
  const router = useRouter();

  const logUserOut = async () => {
    signOut({ redirect: false, callbackUrl: redirect }).then(() => {
      toast.success(message);
      resetSession().then(() => {
        router.push(redirect);
      });
    });
  };

  const logout = throttle(logUserOut, 10000);

  useEffect(() => {
    logout();
  }, []);

  return false;
}
