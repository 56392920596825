/* eslint-disable react/jsx-props-no-spreading */
import {
  Fragment,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  ModalThemeProps,
  modalTheme,
} from "hardcover-ui/theme/components/modal";
import classNames from "lib/classNames";
import { Dialog, Transition } from "@headlessui/react";
import { Capacitor } from "@capacitor/core";
import { usePathname, useSearchParams } from "next/navigation";

type Props = {
  variant?: ModalThemeProps["variant"];
  size?: ModalThemeProps["size"];
  className?: string;
  open?: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<HTMLElement | null> | undefined;
  position?: "center" | "top" | "bottom";
  zIndex?: number;
} & Partial<HTMLAttributes<HTMLDivElement>>;

// Close the modal if navigating to a different page
function ModalController({ onClose }: { onClose: () => void }) {
  const path = usePathname();
  const params = useSearchParams();
  const [initialPath] = useState(path);
  const [initialParams] = useState(params);

  // Close the modal if navigating to a different page
  useEffect(() => {
    if (onClose && (path !== initialPath || params !== initialParams)) {
      onClose();
    }
  }, [path, params]);
  return <></>;
}

const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      children,
      initialFocus,
      variant = "primary",
      size = "sm",
      open = false,
      onClose,
      position = "center",
      zIndex = 40,
      className,
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      modalTheme.variant[variant],
      modalTheme.size?.[size],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      ...rest,
    };

    return (
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className={`modal fixed inset-0 overflow-y-auto`}
          open={open}
          onClose={onClose}
          initialFocus={initialFocus}
          style={{ zIndex }}
        >
          <div className="flex justify-center sm:pt-4 sm:px-4 text-center sm:block sm:p-0 max-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-70 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div
                style={{
                  paddingTop: Capacitor.isNativePlatform()
                    ? "env(safe-area-inset-top)"
                    : "auto",
                }}
                className={classNames(
                  "bg-white dark:bg-gray-800 md:bg-transparent dark:md:bg-transparent",
                  "flex min-h-full lg:p-4 text-center",
                  position === "center" ? "items-center justify-center" : "",
                  position === "top" ? "items-start justify-start" : ""
                )}
              >
                {onClose && <ModalController onClose={onClose} />}
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel {...props}>{children}</Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default Modal;
