"use client";

import { lazy, Suspense } from "react";
import Container from "hardcover-ui/components/Container";
import Modal from "hardcover-ui/components/Modal";
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";
import { SearchContextType } from "../../search/types";

const QuickSearchForm = lazy(() => import("./QuickSearchForm"));

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  searchOptions?: SearchContextType;
};

export function QuickSearchModal({
  isOpen = false,
  onClose,
  searchOptions,
}: Props) {
  return (
    <Modal onClose={onClose} open={isOpen} position="top">
      <Container>
        <Suspense fallback={<LoadingSpinner size="lg" variant="layout" />}>
          {isOpen && (
            <QuickSearchForm
              searchOptions={{
                ...searchOptions,
                ...{ onClose, isOpen },
              }}
            />
          )}
        </Suspense>
      </Container>
    </Modal>
  );
}
