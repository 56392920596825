"use client";

import { ReactElement, useEffect } from "react";
import { useRouter } from "next/navigation";
import Container from "hardcover-ui/components/Container";

export default function RedirectView({
  url,
  children,
}: {
  children?: ReactElement;
  url: string;
}) {
  const router = useRouter();

  useEffect(() => {
    router.replace(url);
    router.refresh();
  }, []);

  if (!children) {
    return false;
  }
  return <Container variant="layout">{children}</Container>;
}
