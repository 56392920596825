import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/ApolloNextAppProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/experimental-nextjs-app-support/dist/ssr/hooks.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/_fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-sans\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/_fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/new-spirit-400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/new-spirit-600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-serif\"}],\"variableName\":\"newSpirit\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/background/BackgroundManager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/background/CurrentUser/CurrentUserClientLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/join/Signout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/SharedPlaceholders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/views/RedirectView.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
