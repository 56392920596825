"use client";

import "react-tooltip/dist/react-tooltip.css";
import { lazy } from "react";
import QuickSearchModal from "components/search/QuickSearchModal";
import { getIsLoggedIn } from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";
import { colors } from "constants/tailwind";
import { useIsScreenSizeBiggerThanTailwindSize } from "hooks/useIsScreenSizeBiggerThanTailwindSize";
import { Toaster } from "sonner";

const LoginModal = lazy(() => import("components/join/LoginModal"));
const Tooltip = lazy(() =>
  import("react-tooltip").then((module) => ({ default: module.Tooltip }))
);

const SharedPlaceholders = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLarge = useIsScreenSizeBiggerThanTailwindSize("lg");

  return (
    <>
      <QuickSearchModal />
      {isLoggedIn === false && <LoginModal />}
      {isLarge && (
        <Tooltip
          id="tooltip"
          place="top"
          opacity={1}
          style={{
            backgroundColor: colors.indigo[500],
            color: "#fff",
            zIndex: 100,
          }}
        />
      )}
      <Toaster
        position="top-right"
        expand
        closeButton
        duration={3000}
        visibleToasts={3}
        toastOptions={{
          unstyled: true,
          classNames: {
            toast:
              "flex items-center rounded-xl w-full p-2 border-2 text-base mt-12 gap-0.5",
            error: "bg-red-500 border-red-400 text-red-50",
            success: "bg-green-400 border-green-600 text-green-900",
            warning: "bg-yellow-400 border-yellow-600 text-yellow-900",
            info: "bg-blue-400 border-blue-600 text-blue-900",
          },
        }}
      />
    </>
  );
};

export default SharedPlaceholders;
