import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const getState = (state: RootState) => state;

export const getSearchChannel = createSelector(
  getState,
  (state) => state.search
);

export const getSearchVisibleSelector = createSelector(
  getSearchChannel,
  ({ searchVisible }) => {
    return searchVisible;
  }
);

export const getNextSearchTerm = createSelector(
  getSearchChannel,
  ({ nextSearchTerm }) => {
    return nextSearchTerm;
  }
);

export const getSearchTerm = createSelector(
  getSearchChannel,
  ({ searchTerm }) => {
    return searchTerm;
  }
);

export const getSearchType = createSelector(
  getSearchChannel,
  ({ activeSearchType }) => {
    return activeSearchType;
  }
);
export const getNextSearchType = createSelector(
  getSearchChannel,
  ({ nextActiveSearchType }) => {
    return nextActiveSearchType;
  }
);

export const getSearchSelected = createSelector(
  getSearchChannel,
  ({ searchSelected }) => {
    return searchSelected;
  }
);

export const getSearchTypes = createSelector(
  getSearchChannel,
  ({ searchTypes }) => {
    return searchTypes;
  }
);

export const getSearchIndex = createSelector(
  getSearchChannel,
  ({ indexName }) => {
    return indexName;
  }
);

export const getSearchSetting = createSelector(
  getSearchChannel,
  ({ indexName, searchTypes }) => {
    return {
      indexName,
      searchTypes,
    };
  }
);
