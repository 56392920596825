"use client";

/* eslint-disable import/no-unresolved */
import { ReactNode } from "react";
import { Provider as ReduxProvider } from "react-redux";
import ServerErrorView from "components/views/ServerErrorView";
import { ErrorBoundary } from "lib/bugsnag/client";
import { store } from "store";
import { ApolloWrapper } from "lib/apollo/ssrClient";
import { HardcoverSession } from "lib/authorization/tokenLoader";

export default function Providers({
  children,
  session,
}: {
  children: ReactNode;
  session: HardcoverSession;
}): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={ServerErrorView}>
      <ApolloWrapper session={session}>
        <ReduxProvider store={store}>{children}</ReduxProvider>
      </ApolloWrapper>
    </ErrorBoundary>
  );
}
